"use client";

import { Button } from "@evenli-platform/ui/components/button-onboarding";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/onboarding/form-onboarding";
import { Input } from "@/components/ui/onboarding/input-onboarding";
import { zodResolver } from "@hookform/resolvers/zod";
import { useSearchParams, useRouter } from "next/navigation";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { useTranslations } from "next-intl";

import { signIn, type SignInInput, AuthError, signOut } from "aws-amplify/auth";
import { signInSchema, SignInSchemaType } from "@/lib/types";
import { getAuthError } from "@/lib/cognito/util";
import Link from "next/link";
import { InitiateAuthException } from "@/lib/cognito/errors";

interface Props {
  onSuccessLogIn?: () => void;
  onNoAccount?: () => void;
}

export const UserAuthForm = ({ onSuccessLogIn, onNoAccount }: Props) => {
  // We need this for conditional rendering for regural SignIn and SignIn while accepting an invitation
  const isToken = useSearchParams().get("token");
  const nextAction = useSearchParams().get("next");
  const router = useRouter();
  const t = useTranslations("Onboarding");
  const tE = useTranslations("Errors");

  async function handleSignIn({ username, password }: SignInInput) {
    try {
      await signOut();
      const { nextStep } = await signIn({ username, password });
      if (nextStep.signInStep === "DONE") {
        if (onSuccessLogIn) {
          onSuccessLogIn(); // if SignInForm was called from one of the invitation's flows
        } else {
          router.push(nextAction ?? "/settings/organizations");
        }
      } else if (nextStep.signInStep === "CONFIRM_SIGN_UP") {
        throw new AuthError({
          message: "User is not confirmed",
          name: InitiateAuthException.UserNotConfirmedException,
        });
      }
    } catch (error) {
      if (error instanceof AuthError) {
        const errorMessage = getAuthError(error);

        if (errorMessage) {
          toast.error(tE("something_went_wrong"), {
            description: tE(errorMessage),
          });

          return;
        }
      }

      toast.error(tE("something_went_wrong"), {
        description: tE("something_went_wrong_please_try_again"),
      });
    }
  }

  const formSignIn = useForm<SignInSchemaType>({
    resolver: zodResolver(signInSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit = async (data: SignInSchemaType) => {
    await handleSignIn({
      username: data.email,
      password: data.password,
    });
  };

  return (
    <div className="max-sm:w-[calc(100%-50px)] my-[25px] w-[460px] p-[30px] max-sm:p-[20px] flex flex-col justify-start items-start gap-5 rounded-[10px] max-sm:rounded-[15px] border-2 border-opacity-20 border-white bg-white bg-opacity-10">
      <div className="flex flex-col justify-start items-start">
        <div className="text-white text-[28px] font-semibold leading-[45px]">
          {t("sign_in")}
        </div>
        <div className="self-stretch text-white text-base font-normal leading-normal">
          {t("with_your")}&nbsp;
          {isToken && t("to_accept_the_invitation")}
        </div>
      </div>
      <Form {...formSignIn}>
        <form
          onSubmit={formSignIn.handleSubmit(onSubmit)}
          className="w-full space-y-2"
        >
          <FormField
            control={formSignIn.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("email")}</FormLabel>
                <FormControl>
                  <Input
                    type="email"
                    disabled={formSignIn.formState.isSubmitting}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={formSignIn.control}
            name="password"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("password")}</FormLabel>
                <FormControl>
                  <Input
                    type="password"
                    disabled={formSignIn.formState.isSubmitting}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <Button
            loading={formSignIn.formState.isSubmitting}
            className="ml-auto w-full"
            type="submit"
          >
            {t("sign_in")}
          </Button>
        </form>
      </Form>

      <div className="flex-grow w-full h-[0px] mt-5 border border-zinc-200 border-opacity-20"></div>

      <div className="w-full flex flex-col justify-center items-center gap-2.5">
        <Button variant="ghost" asChild>
          <Link href={"/forgot-password"}>{t("forgot_password")}</Link>
        </Button>
        {onNoAccount && (
          <div className="flex">
            <span className="opacity-70">
              {t("dont_have_an_account")}&nbsp;
            </span>
            <button
              onClick={() => {
                // Set hasAccount to false in the parent component
                onNoAccount();
              }}
              className="flex justify-center items-center rounded-[10px] transition-colors duration-200 hover:underline underline-offset-4"
            >
              {t("create_an_account")}.
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
